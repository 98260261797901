import React from 'react'
import { useTranslation } from 'react-i18next'
import Integration from '../../components/MyApp/Integration'
import ServiceApp from '../../components/MyApp/ServiceApp'
import { PAGE_VIEWED } from '../../constants/metrics'
import MetricsService from '../../services/metricsService'

interface ICreateApplicationProps {
  hasCurrentApplications?: boolean
  isServiceApp?: boolean
}
const CreateApplication: React.FC<ICreateApplicationProps> = ({
  hasCurrentApplications,
  isServiceApp,
}) => {
  const { t } = useTranslation()
  const metricsService = MetricsService.getInstance()

  metricsService.track(PAGE_VIEWED, {
    pageName: `Create ${isServiceApp ? 'Service App' : 'Integrations'}`,
  })

  return (
    <section className="create-application">
      {isServiceApp ? (
        <>
          <div>
            <h2>{t('serviceApp.newApp')}</h2>
          </div>
          <ServiceApp hasCurrentApplications={hasCurrentApplications} />
        </>
      ) : (
        <>
          <div>
            <h2>{t('integrations.newIntegration')}</h2>
          </div>
          <Integration hasCurrentApplications={hasCurrentApplications} />
        </>
      )}
    </section>
  )
}

export default CreateApplication
