import React from 'react'
import { useTranslation } from 'react-i18next'
import ServiceIcon from '../../assets/svgs/service-app-card-icon.svg'
import IntegrationIcon from '../../assets/svgs/integrations-card-icon.svg'
import { PAGE_VIEWED } from '../../constants/metrics'
import MetricsService from '../../services/metricsService'
import MyAppCard from '../../components/MyAppCard'

const appCards = [
  {
    icon: <IntegrationIcon width="24px" height="24px" />,
    title: 'integrations.integration',
    description: 'myApps.appTypeCard.integrationDescription',
    buttonText: 'myApps.appTypeCard.integrationButtonText',
    formLink: '/my-apps/new/integration',
    documentationLink: '/documentation/integrations',
  },
  {
    icon: <ServiceIcon width="24px" height="24px" />,
    title: 'myApps.appTypeCard.serviceTitle',
    description: 'myApps.appTypeCard.serviceDescription',
    buttonText: 'myApps.appTypeCard.serviceButtonText',
    formLink: '/my-apps/new/service-app',
    documentationLink: '/documentation/service-apps',
  },
]

interface INewAppProps {
  hasCurrentApplications: boolean
}

const NewApp: React.FC<INewAppProps> = ({ hasCurrentApplications }) => {
  const { t } = useTranslation()
  const metricsService = MetricsService.getInstance()

  metricsService.track(PAGE_VIEWED, {
    pageName: 'Create New App',
  })

  return (
    <section id="create-new-app">
      <h1>{t('myApps.createNewApp')}</h1>
      <div className="main-content">
        {appCards.map((card, i) => {
          return (
            <MyAppCard
              key={i}
              icon={card.icon}
              title={t(card.title)}
              description={t(card.description)}
              buttonText={t(card.buttonText)}
              formLink={card.formLink}
              documentationLink={card.documentationLink}
              hasCurrentApplications={hasCurrentApplications}
            />
          )
        })}
      </div>
    </section>
  )
}

export default NewApp
