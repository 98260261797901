export interface IIntegration {
  description: string
  name: string
  type?: ApplicationTypes
  redirectionURIs: string[]
  registrationDate: string[]
  scopes: string[]
  id: string
  secret?: string
  companyName?: string
  submissionDate?: string
  isFeatured?: boolean
  contactEmail?: string
  instructions?: string
  tagLine?: string
  categories?: string[]
  supportUrl?: string
  privacyUrl?: string
  companyUrl?: string
  contactName?: string
  ciscoContact?: string
  appContext?: string[]
  shortTagLine?: string
  submissionStatus?: string
  productUrl?: string
  logo?: string
  screenshot1?: string
  screenshot2?: string
  screenshot3?: string
  videoUrl?: string
  clientId?: string
  clientSecret?: string
}

export interface IIntegrations {
  total: number
  data: IIntegration[]
}

export enum ApplicationTypes {
  ServiceApp = 'serviceApp',
  Integration = 'integration',
}

export interface IServiceApp {
  description: string
  name: string
  type: ApplicationTypes
  registrationDate: string[]
  scopes: string[]
  id: string
  secret?: string
  companyName?: string
  submissionDate?: string
  isFeatured?: boolean
  contactEmail?: string
  instructions?: string
  tagLine?: string
  categories?: string[]
  supportUrl?: string
  privacyUrl?: string
  companyUrl?: string
  contactName?: string
  ciscoContact?: string
  appContext?: string[]
  shortTagLine?: string
  submissionStatus?: string
  orgSubmissionStatus?: 'none' | 'submitted'
  productUrl?: string
  logo?: string
  screenshot1?: string
  screenshot2?: string
  screenshot3?: string
  videoUrl?: string
  clientId?: string
  clientSecret?: string
  authorizationsList?: IAuthorizations[]
  authorizationsCount?: number
}

export interface IAuthorizations {
  authorizerId: string
  orgId: string
  authorizationDate: string
  orgName: string
  validDomains: string[]
}

export interface IApplications {
  total: number
  data: Array<IServiceApp | IIntegration>
}
