export const NAME_VALIDATOR = {
  pattern: /^\w+( +\w+)*$/,
  message: 'myApps.nameValidationMessage',
  length: 45,
}

export const DESCRIPTION_VALIDATOR = {
  pattern: /[\S]/,
  message: 'myApps.descriptionValidationMessage',
  length: 1024,
}

export const REQUIRED_FIELD_VALIDATOR = {
  pattern: /[\S]/,
  message: 'apphubSubmission.requiredFieldValidationMessage',
  length: 100,
}

export const TESTING_INSTRUCTIONS_VALIDATOR = {
  pattern: /^(?!\s+$).+/,
  message: 'apphubSubmission.testingInstructionValidationMessage',
  length: 1024,
}

export const URL_VALIDATOR = {
  pattern: /^\S+:\/{2}[^#]+$/,
  message: 'integrations.redirectUrisValidationMessage',
  length: 2048,
}

export const APPHUB_NAME_VALIDATOR = {
  pattern: /^\w+( +\w+)*$/,
  message: 'apphubSubmission.nameValidationMessage',
  length: 100,
}

export const WEBSITE_URL_VALIDATOR = {
  pattern: /^\S+:\/{2}[^#\/]+?\.[a-zA-Z]{2,3}(?:\/\S*)?$/,
  message: 'apphubSubmission.websiteURLValidatorMessage',
  length: 2048,
}

export const WEBSITE_URL_AND_EMAIL_VALIDATOR = {
  pattern: /^(?:\S+:\/\/[^#\/]+?\.[a-zA-Z]{2,3}(?:\/\S*)?|[^@\s]+@[^@\s]+\.[^@\s]+)$/,
  message: 'apphubSubmission.websiteURLOrEmailValidatorMessage',
  length: 2048,
}

export const EMAIL_VALIDATOR = {
  pattern: /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
  message: 'apphubSubmission.emailValidatorMessage',
  length: 256,
}

export const SUMMARY_VALIDATOR = {
  pattern: /[\S]/,
  message: 'apphubSubmission.summaryValidationMessage',
  length: 256,
}

export const TAGLINE_VALIDATOR = {
  pattern: /[\S]/,
  message: 'apphubSubmission.taglineValidatorMessage',
  length: 24,
}

export const URL_OR_EMPTY_VALIDATOR = {
  pattern: /^(\S+:\/\/[^#]+)?$/,
  message: 'apphubSubmission.websiteURLValidatorMessage',
  length: 2048,
}
