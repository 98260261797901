import { Icon, Input } from '@momentum-ui/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCopy } from '../../../hooks/useCopy'
import EditButtons from '../EditButtons'

interface IValidation {
  pattern: RegExp
  length: number
  message: string
}
interface IActionInputProps {
  className?: string
  textArea?: boolean
  rows?: number
  copyable?: boolean
  showEditButton?: boolean
  name: string
  htmlId: string
  value?: string | string[]
  readOnly?: boolean
  placeholder?: string
  onChange?: Function
  onEdit?: Function
  handleSave?: Function
  handleCancel?: Function
  isLoading?: boolean
  disableSave?: boolean
  onRemoveInput?: Function
  onAddInput?: Function
  addInputButtonText?: string
  validation?: IValidation
  handleValidationState?: Function
}

/**
 * @name ActionInput
 * @param { string } className style classes to apply to component
 * @param { boolean } textArea converts the input field into a textarea input
 * @param { number } rows rows property for textarea
 * @param { boolean } copyable adds a "Copy" button adjacent to input field that adds current value to clipboard
 * @param { boolean } showEditButton makes input disabled by default and adds EditButton component adjacent to input field
 * @param { string } name required field - name property of Input component
 * @param { string } htmlId required field - htmlId proeprty of Input component
 * @param { string | string[] } value value property of Input component
 * @param { boolean } readOnly disables editing
 * @param { string } placeholder default text in Input prior to editing
 * @param { Function } onChange callback function run when input changes
 * @param { Function } onEdit callback function run when "Edit" button is clicked (from showEditButton)
 * @param { Function } handleSave callback function run when "Save" button is clicked (after editing from showEditButton)
 * @param { Function } handleCancel callback function run when "Cancel" button is clicked (after editing from showEditButton)
 * @param { boolean } isLoading property in EditButtons component
 * @param { boolean } disableSave property in EditButtons component
 * @param { Function } onRemoveInput (if value is an array) callback function run when input field cleared by "X" button
 * @param { Function } onAddInput (if value is an array) callback function run when add button is clicked
 * @param { string } addInputButtonText (if value is an array) text for add button
 * @param { IValidation } validation input validation rules
 * @param { Function } handleValidationState callback function for specific validation states
 *
 */
const ActionInput: React.FC<IActionInputProps> = ({
  className,
  textArea,
  rows,
  copyable,
  showEditButton,
  name,
  htmlId,
  value,
  readOnly,
  placeholder,
  onChange,
  onEdit,
  handleSave,
  handleCancel,
  isLoading,
  disableSave,
  onRemoveInput,
  onAddInput,
  addInputButtonText,
  validation,
  handleValidationState,
}) => {
  const { t } = useTranslation()
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [copyClicked, copyText] = useCopy(2000)
  const [hasInputError, setHasInputError] = useState<boolean>(false)
  const [hasMultiInputError, setHasMultiInputError] = useState<{
    [key: string]: boolean
  }>({})

  const hasAnyMultiInputError = (): boolean =>
    Object.keys(hasMultiInputError).reduce(
      (prev: boolean, curr: string) => prev || hasMultiInputError[curr],
      false
    )

  const isReadOnly = (): boolean => {
    if (readOnly) {
      return true
    } else if (showEditButton) {
      return !isEditing
    } else {
      return false
    }
  }

  const handleValidation = (input: string, name: string): boolean => {
    const isInvalid =
      !validation?.pattern.test(input) || input.length > validation.length
    if (handleValidationState) {
      handleValidationState(isInvalid, name)
    }
    return isInvalid
  }

  const renderInput = (): JSX.Element => {
    if (Array.isArray(value)) {
      return (
        <>
          {value.map((val: string, idx) => {
            return (
              <div key={idx} className="multi-input">
                <Input
                  key={idx}
                  className={className}
                  name={`${name}-${idx}`}
                  htmlId={`${htmlId}-${idx}`}
                  value={val}
                  readOnly={isReadOnly()}
                  placeholder={placeholder}
                  onChange={(input: any): void => {
                    if (onChange) {
                      onChange(input, idx)
                    }
                  }}
                  onBlur={(input: any): void => {
                    if (validation) {
                      input.persist()
                      let validationState = handleValidation(
                        input.target.value,
                        name
                      )
                      if (idx > 0 && input.target.value === '') {
                        validationState = false
                      }
                      setHasMultiInputError({
                        ...hasMultiInputError,
                        [idx]: validationState,
                      })
                    }
                  }}
                  messageArr={
                    validation && hasMultiInputError[idx] === true
                      ? [{ message: t(validation.message), type: 'error' }]
                      : []
                  }
                />
                {idx !== 0 && (isEditing || !showEditButton) && (
                  <span>
                    <Icon
                      name="icon-delete_24"
                      onClick={(): void => {
                        if (onRemoveInput) {
                          onRemoveInput(idx)
                          setHasMultiInputError({
                            ...hasMultiInputError,
                            [idx]: false,
                          })
                        }
                      }}
                      data-cy="remove-input-btn"
                    />
                  </span>
                )}
              </div>
            )
          })}
          {(!showEditButton || isEditing || readOnly) && (
            <>
              <button
                className="add-input-btn inline-text-btn"
                onClick={(): void => {
                  if (onAddInput) {
                    onAddInput()
                  }
                }}
                data-cy="add-input-btn"
              >
                <Icon name="icon-add_14" />{' '}
                <span>{addInputButtonText || t('actionButtons.add')}</span>
              </button>
            </>
          )}
        </>
      )
    } else {
      return (
        <Input
          className={className}
          name={name}
          htmlId={htmlId}
          value={value}
          readOnly={isReadOnly()}
          placeholder={placeholder}
          onChange={(input: any): void => {
            if (onChange) {
              onChange(input)
            }
          }}
          onBlur={(input: any): void => {
            if (validation) {
              const validationState = handleValidation(input.target.value, name)
              setHasInputError(validationState)
            }
          }}
          messageArr={
            hasInputError && validation
              ? [{ message: t(validation.message), type: 'error' }]
              : []
          }
        />
      )
    }
  }

  const renderActionButton = (): JSX.Element | null => {
    if (showEditButton && (!isEditing || readOnly)) {
      return (
        <button
          className="input-action-btn inline-text-btn"
          onClick={(): void => {
            setIsEditing(true)
            if (onEdit) {
              onEdit()
            }
          }}
          data-cy={`edit-action-btn-${name}`}
          data-testid={`edit-action-btn-${name}`}
        >
          {t('actionButtons.edit')}
        </button>
      )
    } else if (copyable && readOnly) {
      return (
        <button
          className="input-action-btn inline-text-btn"
          onClick={(): void => {
            if (value) {
              copyText(value)
            }
          }}
          data-cy={`copy-action-field-btn-${name}`}
        >
          {copyClicked ? (
            <>
              <Icon className="btn-icon" name="check_16" />
              {t('copyButton.copied')}
            </>
          ) : (
            t('actionButtons.copy')
          )}
        </button>
      )
    }
    return null
  }

  return (
    <>
      <div
        className={`action-input-container ${
          textArea || Array.isArray(value) ? 'action-block' : ''
        }`}
      >
        {textArea ? (
          <div
            className={`md-input-container ${hasInputError ? 'md-error' : ''} ${
              isReadOnly() ? 'md-read-only' : ''
            }`}
          >
            <textarea
              className={`md-input ${className ? className : ''} ${
                isReadOnly() ? 'md-read-only' : ''
              }`}
              name={name}
              onChange={(input): void => {
                if (onChange) {
                  onChange(input)
                }
              }}
              onBlur={(input: any): void => {
                const validationState = handleValidation(
                  input.target.value,
                  name
                )
                setHasInputError(validationState)
              }}
              id={htmlId}
              value={value}
              rows={rows}
              readOnly={isReadOnly()}
            />
            {hasInputError && validation && (
              <div className="md-input__messages">
                <div className="md-input__message" role="alert">
                  {t(validation.message)}
                </div>
              </div>
            )}
          </div>
        ) : (
          renderInput()
        )}
        {renderActionButton()}
      </div>
      {showEditButton && handleSave && (
        <EditButtons
          show={isEditing}
          disableSave={disableSave || hasInputError || hasAnyMultiInputError()}
          handleCancel={(): void => {
            if (handleCancel) {
              handleCancel()
            }
            setIsEditing(false)
            setHasInputError(false)
            setHasMultiInputError({})
          }}
          handleSave={async (): Promise<void> => {
            await handleSave()
            setIsEditing(false)
          }}
          isLoading={isLoading}
          existingApplication
        />
      )}
    </>
  )
}

export default ActionInput
