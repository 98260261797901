import { Button } from '@momentum-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface IEditButtonsProps {
  show: boolean
  handleCancel: Function
  handleSave: Function
  isLoading?: boolean
  existingApplication?: boolean
  disableSave?: boolean
  appBtnText?: string
}

const EditButtons: React.FC<IEditButtonsProps> = ({
  show,
  handleCancel,
  handleSave,
  isLoading,
  existingApplication,
  disableSave,
  appBtnText,
}) => {
  const { t } = useTranslation()
  const addAppBtnText = appBtnText || t('integrations.addIntegration')

  return (
    <>
      {show && (
        <div className="edit-buttons-container">
          <Button
            id="cancel-button"
            ariaLabel={t('actionButtons.cancel')}
            onClick={handleCancel}
            role="button"
            data-cy="cancel-btn"
            data-testid="cancel-btn"
          >
            {t('actionButtons.cancel')}
          </Button>
          <Button
            className={disableSave ? 'disabled' : ''}
            id="submit-button"
            ariaLabel={
              existingApplication ? t('actionButtons.save') : addAppBtnText
            }
            type="submit"
            color="blue"
            onClick={handleSave}
            loading={isLoading}
            data-cy="submit-application-btn"
            data-testid="submit-btn"
          >
            {existingApplication ? t('actionButtons.save') : addAppBtnText}
          </Button>
        </div>
      )}
    </>
  )
}

export default EditButtons
