import { Button, List } from '@momentum-ui/react'
import { navigate } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import WebexCCLogo from '../../assets/svgs/webex-cc-logo.svg'
import ApplicationListItem from '../../components/ApplicationListItem'
import { PAGE_VIEWED } from '../../constants/metrics'
import httpService from '../../services/httpService'
import { logError } from '../../services/loggerService'
import MetricsService from '../../services/metricsService'
import { retrieveFeatureFlags, retrieveToken } from '../../state/auth'
import { AppState } from '../../state/store'
import { getBffBaseUrl } from '../../services/configService'
import { getCIUrls } from '../../utils/auth.utils'
import { hasValidFeatureFlag } from '../../utils/general.utils'
import { IApplications } from '../../interfaces/myAppInterfaces'
interface IApplicationListProps {
  setHasError: Function
}
const ApplicationList: React.FC<IApplicationListProps> = ({ setHasError }) => {
  const { t } = useTranslation()
  const token = useSelector((state: AppState) => retrieveToken(state))
  const { idBrokerUrl } = getCIUrls()
  const userFeatureFlags = useSelector((state: AppState) =>
    retrieveFeatureFlags(state)
  )
  const [dataFetched, setDataFetched] = useState<any>(null)
  const hasServiceAppFeatureFlag = hasValidFeatureFlag(
    userFeatureFlags,
    `${process.env.GATSBY_SERVICE_APP_NEEDS_FEATURE_FLAG}`,
    `${process.env.GATSBY_SERVICE_APP_FEATURE_FLAG}`
  )

  const routeToCreatePage = (hasApplications: boolean): void => {
    const createPath = hasServiceAppFeatureFlag
      ? '/my-apps/new'
      : '/my-apps/create'
    navigate(createPath, { state: { hasCurrentApplications: hasApplications } })
  }

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const httpConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          idBrokerHost: idBrokerUrl,
          ...(hasServiceAppFeatureFlag
            ? { type: 'serviceApp,integration' }
            : {}),
        },
      }
      const requestUri = `${getBffBaseUrl()}/v1/${
        hasServiceAppFeatureFlag ? 'applications' : 'integrations'
      }`

      try {
        const httpResponse = await httpService.get(requestUri, httpConfig)

        if (httpResponse.data.total === 0) {
          routeToCreatePage(false)
        } else {
          setDataFetched(httpResponse.data)
        }
      } catch (err) {
        setHasError(true)
        logError(`An error has occurred while fetching applications ${err}`)
      }
    }

    fetchData()
  }, [setHasError, token, idBrokerUrl])

  const metricsService = MetricsService.getInstance()

  metricsService.track(PAGE_VIEWED, {
    pageName: 'List Applications',
  })

  return (
    <>
      {dataFetched?.total > 0 ? (
        <section className="applications">
          <div className="applications-row">
            <h2>{t('myApps.myApps')}</h2>
            <Button
              className="md-button md-button--blue"
              onClick={(): void => {
                routeToCreatePage(true)
              }}
              // TODO: Add tooltip to let user know why this is disabled & FIGURE OUT LIMIT
              disabled={dataFetched?.total >= 10}
              data-cy="create-app-btn"
            >
              {t('myApps.createNewApp')}
            </Button>
          </div>
          <div className="applications-row">
            {dataFetched?.data?.length ? (
              <List id="applications-list" data-cy="applications-list">
                {(dataFetched?.data as IApplications['data']).map(
                  (data, i: number) => {
                    return (
                      <ApplicationListItem
                        key={i}
                        name={data.name}
                        id={data.id}
                        registrationDate={data.registrationDate}
                        type={data?.type}
                      />
                    )
                  }
                )}
              </List>
            ) : null}
          </div>
        </section>
      ) : (
        <div className="loading-screen">
          <WebexCCLogo className="loading-stuff" />
        </div>
      )}
    </>
  )
}

export default ApplicationList
